document.addEventListener('turbolinks:load', () => {

  //ここにjsの記述を追加

  copyTextField =function (srcField, dest) {
    if (srcField.value.length > 0) {
      it2 = document.getElementById(dest);
      if (it2 != null) {
        
        if (it2.value.length < 1) {
          it2.value = srcField.value;
        }
      }
    }
  };
  
})